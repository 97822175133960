import React from "react";
import "./PrivateSale.css";

const PrivateSale = () => {
  const walletAddress = "SOL_ADDRESS_HERE"; // Укажите адрес кошелька здесь

  return (
    <div className="private-sale">
      <header className="header">
        <h1 className="title">SOLANTA Private Sale</h1>
        <p className="subtitle">Invest now and secure your SOLANTA tokens!</p>
      </header>
      <div className="content">
        <div className="info-box">
          <h2>Private Sale Details</h2>
          <p>💰 **Token Price**: $0.12 per SOLANTA</p>
          <p>🌟 **Total Tokens Available for PrivateSale**: 12,500,000</p>
          <p>💸 **Target Fundraising Amount**: $1,250,000</p>
        </div>
        <div className="instructions">
          <h2>How to participate:</h2>
          <ol>
            <li>
              Send a minimum of **0.5 SOL** to the wallet address below. Ensure
              the address is correct before sending.
            </li>
            <li>
              After the transaction is confirmed, you will receive your SOLANTA
              tokens to the same wallet address.
            </li>
          </ol>
          <p className="warning">
            ⚠️ Transactions below 0.5 SOL will not be processed.
          </p>
        </div>
        <div className="wallet">
          <h2>Wallet Address for Payment:</h2>
          <div className="wallet-address">{walletAddress}</div>
          <button
            onClick={() => navigator.clipboard.writeText(walletAddress)}
            className="copy-button"
          >
            Copy Wallet Address
          </button>
        </div>
        <div className="closing-warning">
          🚨 This page will be **closed** once the private sale ends!
        </div>
      </div>
      <footer className="footer">
        <p>
          ⚠️ Make sure to send SOL only from wallets you control (e.g., Phantom
          or Solflare). Do not use exchanges for transactions.
        </p>
        <p>
          For questions, contact our support team via official channels.
        </p>
      </footer>
    </div>
  );
};

export default PrivateSale;
